import styled from "styled-components"

import { TBreakdownItem } from "src/components/Account/BillingPortal/ChangePlan/Breakdown/changePlanBreakdownTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

type BreakdownCardProps = {
  data: TBreakdownItem[]
}

export function BreakdownCards({ data }: BreakdownCardProps) {
  return (
    <CardsBox>
      {data.map((item) => (
        <Card key={item.state} item={item} />
      ))}
    </CardsBox>
  )
}

const CardsBox = styled.div`
  display: grid;
  row-gap: ${spacing.M};

  & > :not(:first-child) {
    border-top: 1px solid ${mColors.divider};
    padding-top: ${spacing.M};
  }
`

function Card({ item }: { item: TBreakdownItem }) {
  const { t, langKeys } = useTranslate()
  const tState = t(langKeys[item.state])
  const tCharge = t(langKeys.charge)
  const tDays = t(langKeys.days)
  const tHomes = t(langKeys.home, { count: 0 })
  const tProrated = t(langKeys.prorated_price_per_home)

  return (
    <GridBox variant="bodyS" color="secondary">
      <Cell variant="subtitleS" $fullWidth $capitalizeFirst>
        {tState}
      </Cell>

      <Cell>{tHomes}</Cell>
      <Cell textAlign="end">{item.quantity}</Cell>

      <Cell>{tDays}</Cell>
      <Cell textAlign="end">{item.days_until_renewal}</Cell>

      <Cell>{tProrated}</Cell>
      <Cell textAlign="end">{item.formattedProratedAmount}</Cell>

      <Cell variant="subtitleS">{tCharge}</Cell>
      <Cell variant="subtitleS" textAlign="end">
        {item.formattedAmount}
      </Cell>
    </GridBox>
  )
}

const GridBox = styled(MText)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const Cell = styled(MText)<{
  $fullWidth?: boolean
  $capitalizeFirst?: boolean
}>`
  ${({ $fullWidth }) => $fullWidth && "grid-column: 1 / -1"};
  ${({ $capitalizeFirst }) =>
    !!$capitalizeFirst &&
    `display: "inline-block";
     &:first-letter {
       text-transform: capitalize;
      };
  `};
  color: ${mColors.textPrimary};
`
