import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function UpgradeCostCalculationInfo() {
  const { t, langKeys } = useTranslate()
  return (
    <MBanner type="info">
      <MText variant="subtitle" color="unset" marginBottom={spacing.XS2}>
        {t(langKeys.upgrade_cost_dialog_title)}
      </MText>
      <MText color="unset">{t(langKeys.upgrade_cost_dialog_body)}</MText>
    </MBanner>
  )
}
