import {
  TBreakdownItem,
  TPostUpdateSubscriptionEstimateResponseBreakdownItem,
} from "src/components/Account/BillingPortal/ChangePlan/Breakdown/changePlanBreakdownTypes"
import { formatPrice } from "src/utils/formatPrice"

export function getBreakdownItem(
  i: TPostUpdateSubscriptionEstimateResponseBreakdownItem
): TBreakdownItem {
  const formattedProratedAmount = formatPrice(
    i.prorated_unit_amount / 100,
    i.currency_code,
    2,
    2,
    false
  )
  const formattedAmount = formatPrice(
    i.amount / 100,
    i.currency_code,
    2,
    2,
    false
  )
  return { ...i, formattedAmount, formattedProratedAmount }
}
