import { ChangePlanBreakdown } from "src/components/Account/BillingPortal/ChangePlan/Breakdown/ChangePlanBreakdown"
import { TChangePlanBreakdownData } from "src/components/Account/BillingPortal/ChangePlan/Breakdown/changePlanBreakdownTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { IMDialog, MDialog } from "src/ui/Dialog/MDialog"

import { UpgradeCostCalculationInfo } from "./UpgradeCostCalculationInfo"

type Props = {
  data: TChangePlanBreakdownData
} & Omit<IMDialog, "title">

export function ChangePlanBreakDownDialog({
  data,
  onClose,
  ...dialogProps
}: Props) {
  const { t, langKeys } = useTranslate()

  return (
    <MDialog
      {...dialogProps}
      title={t(langKeys.change_plan_breakdown)}
      onClose={onClose}
    >
      <ChangePlanBreakdown data={data} />

      <UpgradeCostCalculationInfo />
    </MDialog>
  )
}
